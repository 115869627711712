import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../../components/Layout"
import * as styles from "../../styles/firm-philosophy.module.css"
import Sidebar from "../../components/Sidebar"
import { GatsbySeo } from "gatsby-plugin-next-seo"

export default function TransportationLaw({ data }) {
  return (
    <Layout>
      <GatsbySeo
        title="Charleston South Carolina Transportation Lawyers | Tecklenburg, Jenkins & Jacoby, LLC"
        description="Tecklenburg, Jenkins & Jacoby understands that transportation companies face a wide variety of possible legal
            challenges in the course of their daily operations. Federal
            regulations control many aspects of the industry, and compliance
            with the numerous and conflicting transport rules and laws can be
            difficult."
        keywords="Charleston, South Carolina, Maritime Attorney, Maritime Lawyer, Lawyer, Maritime, Attorney, Admiralty Attorney, Admiralty Lawyer, Admiralty, Business Attorney, Business Lawyer, Business, Railroad Attorney, Railroad Lawyer, Railroad, Litigation Attorney, Litigation Lawyer, Litigation, Construction Attorney, Construction Lawyer, Construction, Estate Attorney, Estate Lawyer, Estate"
        canonical="https://www.tecklaw.net/practice-areas/south-carolina-transportation-law/"
      />
      <section className={styles.header}>
        <section className={styles.sidebar}>
          <Sidebar data={data} />
        </section>
        <section className={styles.content}>
          <h1>South Carolina Transportation Lawyers</h1>
          {/* <h2>TRANSPORTATION LAW</h2> */}
          <video widht="700" controls autoplay="true" muted>
            <source src="/transportationConverted.mp4" type="video/mp4" />
            Your browser does not suppport HTML5 video.
          </video>
          <p>
            Transportation companies face a wide variety of possible legal
            challenges in the course of their daily operations. Federal
            regulations control many aspects of the industry, and compliance
            with the numerous and conflicting transport rules and laws can be
            difficult. At the same time, transport companies face all of the
            other legal challenges other companies face, like personal injury,
            employment and safety issues.
          </p>
          <p>
            Tecklenburg, Jenkins & Jacoby provides unmatched expertise in
            transportation law. Businesses in the transportation industry need a
            lawyer to handle the significant legal and regulatory challenges
            that they face.
          </p>
          <h2>South Carolina Transportation Defense Litigation</h2>
          <p>
            Transportation laws are highly complex and difficult to navigate on
            your own. We provide the knowledge and skill clients need when
            handling issues involving:
          </p>
          <ul>
            <li>
              <h3>
                Personal Injury/Wrongful Death Under State law,{" "}
                <a href="https://uscode.house.gov/view.xhtml?req=46+usc+30104&f=treesort&fq=true&num=4&hl=true&edition=prelim&granuleId=USC-prelim-title46-section30104">
                  The Jones Act
                </a>
                , The Federal Employers Liability Act (FELA),{" "}
                <a href="https://uscode.house.gov/view.xhtml?req=granuleid%3AUSC-prelim-title46-chapter303&saved=%7CNDYgdXNjIDMwMzAy%7CdHJlZXNvcnQ%3D%7CdHJ1ZQ%3D%3D%7C2%7Ctrue%7Cprelim&edition=prelim">
                  The Death On The High Seas Act (DOHSA)
                </a>{" "}
                and General Maritime Law
              </h3>
            </li>
            <li>
              <h3>
                Third-Party Claims Brought by Longshoremen/Ship Repair Workers{" "}
                <a href="https://www.dol.gov/owcp/dlhwc/lhwca.htm#.UHRPGVGK8SI">
                  Under The Longshore and Harbor Workers’ Compensation Act
                  (LHWCA)
                </a>
              </h3>
            </li>
            <li>
              <h3>Property Damage Claims</h3>
            </li>
            <li>
              <h3>
                Cargo Damage and Loss Claims Arising From Ocean , Ground, Rail
                And Air Transport, including “
                <a href="https://uscode.house.gov/view.xhtml?req=49+usc+14706&f=treesort&fq=true&num=5&hl=true&edition=prelim&granuleId=USC-prelim-title49-section14706">
                  Carmack Amendment
                </a>
                ” claims
              </h3>
            </li>
            <li>
              <h3>
                Environmental Claims Related to Pollution, Waste Transportation
                And Other Issues including{" "}
                <a href="https://www2.epa.gov/enforcement/oil-pollution-act-opa-and-federal-facilities">
                  Oil Pollution Act (“OPA”),
                </a>{" "}
                <a href="https://www2.epa.gov/laws-regulations/summary-clean-water-act">
                  Clean Water Act (“CWA”),
                </a>
                and{" "}
                <a href="https://www.epa.gov/superfund/superfund-cercla-overview">
                  Comprehensive Environmental Response Compensation and
                  Liability Act (“CERCLA”)
                </a>
              </h3>
            </li>
            <li>
              <h3>Warehouse Claims</h3>
            </li>
          </ul>
          <h2>Commercial Trucking Litigation Defense</h2>
          <p>
            At Tecklenburg, Jenkins & Jacoby, LLC our experienced attorneys are
            active in all aspects of the commercial trucking industry. We have
            represented regional and national intrastate and interstate motor
            carriers, independent truckers, private fleet operators, both
            directly and through their insurers. We can assist you with:
          </p>
          <ul>
            <li>
              <h3>Tractor Trailer and Truck Accidents</h3>
            </li>
            <li>
              <h3>Premises Liability Claims</h3>
            </li>
            <li>
              <h3>Commercial Issues and Disputes</h3>
            </li>
            <li>
              <h3>Contract Disputes</h3>
            </li>
            <li>
              <h3>Drafting of Trucking Transport Documents</h3>
            </li>
            <li>
              <h3>Freight Loss & Cargo Damage Claims and Defense</h3>
            </li>
            <li>
              <h3>Overweight, Oversize and Permit Violations</h3>
            </li>
            <li>
              <h3>Transportation and Logistics Contracts</h3>
            </li>
          </ul>
          <h2>Railroad Litigation & FELA Attorneys</h2>
          <p>
            Railroad crossing cases involving complex issues of federal law,
            numerous experts and multi-day trials, which are handled by our
            litigation attorneys. FELA claims require investigation and analysis
            of each on-the-job injury, to ensure employer resources are
            preserved for only meritorious claims.
          </p>
          <p>
            FELA cases also involve multi-day trials with employees asserting
            substantial damages with numerous experts. Our seasoned litigation
            attorneys defend FELA cases aggressively from the discovery phase to
            the final jury verdict.
          </p>
          <h2>Top Shipping Industry Litigation Attorneys</h2>
          <p>
            Our admiralty attorneys represent businesses and vessel owners in
            every type of legal difficulty that may occur at sea or on the
            waterfront. We represent parties in litigation, administrative, and
            business matters that require expertise in this specialized area of
            law. Our attorneys are experienced in many areas of{" "}
            <Link to="/practice-areas/south-carolina-admiralty-maritime-law">
              maritime law
            </Link>{" "}
            including:
          </p>
          <ul>
            <li>
              <h3>Cargo Claims</h3>
            </li>
            <li>
              <h3>Vessel Arrests</h3>
            </li>
            <li>
              <h3>Inland Marine Claims</h3>
            </li>
            <li>
              <h3>
                <a href="https://uscode.house.gov/view.xhtml?req=granuleid%3AUSC-prelim-title46-chapter305&saved=%7CNDYgdXNjIDMwNTAx%7CdHJlZXNvcnQ%3D%7CdHJ1ZQ%3D%3D%7C3%7Ctrue%7Cprelim&edition=prelim">
                  Limitation of Liability Actions
                </a>
              </h3>
            </li>
            <li>
              <h3>Freight Collections</h3>
            </li>
            <li>
              <h3>Insurance Coverage Disputes</h3>
            </li>
            <li>
              <h3>United States Coast Guard Investigations</h3>
            </li>
            <li>
              <h3>Product Liability Defense</h3>
            </li>
            <li>
              <h3>
                Environmental Claims Related to Pollution, Waste Transportation
                And Other Issues including{" "}
                <a href="https://www2.epa.gov/enforcement/oil-pollution-act-opa-and-federal-facilities">
                  Oil Pollution Act (“OPA”),
                </a>{" "}
                <a href="https://www2.epa.gov/laws-regulations/summary-clean-water-act">
                  Clean Water Act (“CWA”),
                </a>
                and{" "}
                <a href="https://www.epa.gov/superfund/superfund-cercla-overview">
                  Comprehensive Environmental Response Compensation and
                  Liability Act (“CERCLA”)
                </a>
              </h3>
            </li>
            <li>
              <h3>Compliance and Regulatory Matters</h3>
            </li>
            <li>
              <h3>Stevedore Issues</h3>
            </li>
            <li>
              <h3>Warehouse Claims</h3>
            </li>
          </ul>
          <p>
            For more information about our admiralty legal services click here
            and, as always, feel free to <Link to="/contact">contact us</Link>.
          </p>
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query TransportationLawSidebarImages {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "south-carolina-transportation-law" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
